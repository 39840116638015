<template>
    <div>
        <myweipagespromocode :weipagesdata="weipagesdata"></myweipagespromocode>
    </div>
</template>

<script>
import myweipagespromocode from "./weipagespromocode.vue";

import getareajson from '@/assets/json/area.json'
import axios from "axios";

export default {
    name: "weipageshtml5",
    data() {
        return {
            weipagesdata: []
        }
    },
    mounted() {
    },
    methods: {},
    created() {
        // 判断-路由携带-跳转数据
        let routerinfo = this.$route.query;
        if (routerinfo.weipages_id) {
            axios.post(
                "/admin/pages/info",
                {
                    id: routerinfo.weipages_id,
                    flag: 'H5', // 来源于H5的标识
                },
                {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        let getdata = response.data.result.info
                        // 更换-当前页面-title
                        document.title = getdata.title;
                        // 获取-当前页面-数据
                        let weipagesdata = JSON.parse(getdata.template);

                        // 插入-省市区的options
                        let phonecontentdata = this.$utils.inputdelCityCode(weipagesdata, getareajson, 'input')
                        this.weipagesdata = phonecontentdata
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    },
    components: {
        myweipagespromocode
    },
};
</script>

<style scoped>
</style>

